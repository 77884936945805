import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { PropsWithChildren, useEffect } from "react";

import { getRouteType } from "@/util/Routes";

import AxiosInit from "./AxiosInit";

const MsalLayout = dynamic(
  import("@/layouts/MsalLayout").then((module) => module.default)
);

const MsalLayoutWrapper = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const routeType = getRouteType(router.pathname);

  useEffect(() => {
    AxiosInit();
  }, []);

  return routeType == "Anonymous" ? (
    <>{children}</>
  ) : (
    <MsalLayout routeType={routeType}>{children}</MsalLayout>
  );
};

export default MsalLayoutWrapper;
