export const BaseUrl = process.env.NEXT_PUBLIC_REDIRECT_URI!;
export const BaseUrlDefault = "https://folikoinsights.com";
export const ApiBaseUrl = process.env.NEXT_PUBLIC_WORKBENCH_BASEURL!;
export const isProd = process.env.NEXT_PUBLIC_STAGE === "prod";
export const isDevEnv = process.env.NODE_ENV === "development";
export const isLocalhost =
  typeof window !== "undefined" && window.location.host.includes("localhost");
export const RecaptchaSiteKey = "6LeAsIgoAAAAALOyUvy3H2uO0OoqkWSQKN9UXt6u"; // Public key

export const gtagConfig = (
  target: string,
  configParams?: Gtag.ConfigParams | undefined
) => {
  isProd && gtag("config", target, configParams);
};

export const gtagEvent = (
  tag: Gtag.EventNames | (string & {}),
  eventParams?:
    | Gtag.EventParams
    | Gtag.ControlParams
    | Gtag.CustomParams
    | undefined
) => {
  isProd && gtag("event", tag, eventParams);
};
