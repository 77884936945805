import { RouteType } from "@synerai/react-b2c";

interface Route {
  path: string;
  type: RouteType;
}

const ROUTE_TABLE: Route[] = [
  {
    path: "/",
    type: "Unauthenticated",
  },
  {
    path: "/404",
    type: "Anonymous",
  },
  {
    path: "/article/[...slug]",
    type: "Unauthenticated",
  },
  {
    path: "/callback",
    type: "Unauthenticated",
  },
  {
    path: "/checkoutReturn",
    type: "Anonymous",
  },
  {
    path: "/coveragelist",
    type: "Unauthenticated",
  },
  {
    path: "/hello",
    type: "Anonymous",
  },
  {
    path: "/investor-research/[...slug]",
    type: "Anonymous",
  },
  {
    path: "/investor-research",
    type: "Anonymous",
  },
  {
    path: "/lite/[...ticker]",
    type: "Unauthenticated",
  },
  {
    path: "/newsletter",
    type: "Anonymous",
  },
  {
    path: "/validate",
    type: "Anonymous",
  },
  {
    path: "/snapshot",
    type: "Unauthenticated",
  },
];

export const getRouteType = (path: string): RouteType => {
  const routes = ROUTE_TABLE.filter(
    (x) => x.path.toLowerCase() === path.toLowerCase()
  );
  return routes.length == 1 ? routes[0].type : "Authenticated";
};
