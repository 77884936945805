import { RouteType } from "@synerai/react-b2c";
import axios from "axios";

import { ApiBaseUrl } from "@/util/app";

export const AxiosInitCallback =
  (
    routeType: RouteType = "Anonymous",
    AcquireB2CToken?: () => Promise<string>
  ) =>
  async () => {
    axios.defaults.baseURL = ApiBaseUrl;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    if (routeType != "Anonymous" && AcquireB2CToken) {
      const token = await AcquireB2CToken();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return Promise.resolve();
  };

const AxiosInit = (
  routeType: RouteType = "Anonymous",
  AcquireB2CToken?: () => Promise<string>
) => AxiosInitCallback(routeType, AcquireB2CToken)();

export default AxiosInit;
